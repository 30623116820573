import React from "react"
import { motion } from "framer-motion"
import { pageFade, pageTransistion } from "../../helpers/FramerMotionAnim"
import {
  Container,
  Navbar,
  Nav,
  NavItem,
  NavDropdown,
  MenuItem,
} from "react-bootstrap"

import { Parallax } from "react-scroll-parallax"

const AboutHero = () => {
  return (
    <motion.div
      initial="out"
      animate="in"
      exit="out"
      variants={pageFade}
      transition={pageTransistion}
    >
      <section className="about--hero">
        <Container className="text-center ">
          <h1 className="d-none d-lg-block">
            <Parallax x={[-8, 5]}>
              Encouraging <span className="text-monte-carlo"> control </span> of
              health,
              <br />{" "}
            </Parallax>{" "}
            <Parallax x={[3, -5]}> wellbeing and carbon footprint</Parallax>
          </h1>

          <h1 className="d-lg-none">
            Encouraging <span className="text-monte-carlo"> control </span> of
            health, wellbeing and carbon footprint
          </h1>

          <h6 className="p-5">
            Our mission is to create a framework to show other cafés that a
            sustainable <br className="d-none d-lg-block" /> and environmentally
            focused café can be an economically viable business.
          </h6>
        </Container>
      </section>
    </motion.div>
  )
}

export default AboutHero
