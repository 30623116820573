import React from "react"

import AboutHero from "../components/About/AboutHero"
import AboutMission from "../components/About/AboutMission"
import AboutStory from "../components/About/AboutStory"

import AboutGoals from "../components/About/AboutGoals"

import AboutTeam from "../components/About/AboutTeam"

import MainLayout from "../layouts/MainLayout"
import { Helmet } from "react-helmet"

const About = () => {
  return (
    <MainLayout>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="author" content="C.Liu" />

        <title>About | Café Kraken</title>
        <meta
          name="description"
          content="Encouraging control of health, wellbeing and carbon footprint."
        />
      </Helmet>
      <section className="about">
        <AboutHero></AboutHero>
        <AboutMission></AboutMission>
        <AboutStory></AboutStory>
        <AboutGoals></AboutGoals>
        {/* <AboutTeam></AboutTeam> */}
      </section>
    </MainLayout>
  )
}

export default About
