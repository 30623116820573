import React from "react"
import ImgAbout2 from "../../assets/images/About/about2.png"
import { useInView } from "react-intersection-observer"

const AboutStory = () => {
  const [ref, inView] = useInView({ threshold: 0.2 })

  return (
    <section className="about--story" ref={ref}>
      <div className={`container ${inView ? "active" : ""}`}>
        <div className="row">
          <div className="col-12 col-lg-6 about--story__text d-flex justify-content-center flex-column">
            <h2 className="pb-5">The origin of how we started our café </h2>
            <p>
              Although he is a data-driven, analytical CEO and fitness fanatic,
              Gope Walker's real passion is community. Dreaming of a space that
              brings people together, opportunities for people to meet their
              neighbours or just a safe space to relax in, Café Kraken was
              founded with our OX4 in mind.
              <br />
              <br />
              It has also been designed with health and wellbeing in mind, with
              cosy moss ceilings, gentle background music, sound-proof windows
              to reduce road noise and free water on tap to keep everyone
              hydrated.
            </p>
          </div>
          <div className="col-12 col-lg-6 about--story__image">
            <img src={ImgAbout2} alt="" className="w-100" />
          </div>
        </div>
      </div>
    </section>
  )
}

export default AboutStory
