import React from "react"
import ImgAbout1 from "../../assets/images/About/about1.png"

import { useInView } from "react-intersection-observer"

const AboutMission = () => {
  const [refMission, inViewMission] = useInView()

  return (
    <section className="about--mission" ref={refMission}>
      <div className={`container ${inViewMission ? "active" : ""}`}>
        <div className="row">
          <div className="col-12 col-lg-6 p-5 about--mission__img">
            <img src={ImgAbout1} alt="" className="w-100" />
          </div>
          <div className="col-12 col-lg-4 offset-lg-1 d-flex justify-content-center flex-column order-first order-lg-last ">
            <h2 className="pb-5">
              Raising awareness of where our food comes from{" "}
            </h2>
            <p>
              Knowing the ingredients behind your food and where it comes from
              makes it easier to make the right choices for your health.
              <br />
              <br />
              To give you this option, we aim to use local suppliers for every
              ingredient, keep track of our inventory to reduce food waste, look
              for green transport methods, find ways to re-purpose coffee
              grounds and donate our food waste as compost for local allotments
              around the corner. Everybody wins.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AboutMission
