import React from "react"
import ImgAbout4 from "../../assets/images/About/about4.jpg"
import { useInView } from "react-intersection-observer"

const AboutGoals = () => {
  const [ref, inView] = useInView()

  return (
    <section className="about--goals" ref={ref}>
      <div className={`container ${inView ? "active" : ""}`} ref={ref}>
        <div className="row">
          <div className="col-12 col-lg-6 p-5 about--goals__image">
            <img src={ImgAbout4} alt="" className="w-100" />
          </div>
          <div className="col-12 col-lg-4 offset-lg-1 about--goals__text d-flex justify-content-center flex-column order-first order-lg-last">
            <h2 className="pb-5">Our future</h2>
            <p>
              Fortunate to be in an area with so much potential, we want to
              utilise as many local suppliers as economically viable. This will
              be an evolutionary process that will involve determining the
              economic impact of all suppliers' transport methods to bring
              products to our café.
              <br />
              <br />
              We want to involve the local community to grow to produce that we
              can utilise. We have plenty of local allotments nearby to provide
              fruit and veg, and we can donate food waste and coffee grounds as
              compost. Much of this can be transported by bike to make the whole
              process 100% green.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AboutGoals
